<template>

  <div>

    <vue-element-loading
      style="min-height: 500px;"
      :active="carregamentoApi"
      text="Carregando Abilities"
    >
      <img
        src="@/assets/images/logo/logo-loading.gif"
        width="40px"
      >
    </vue-element-loading>

    <ability-lista-add
      :add-ability-sidebar-ativo.sync="addAbilitySidebarAtivo"
      @recarregarTabela="carregarLista()"
    />


    <b-card no-body class="mb-0">

    <div class="m-2">

      <b-row>
        <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
          <label>Exibir</label>
          <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions"
            :clearable="false" class="per-page-selector d-inline-block mx-50" @input="alterarPorPagina"/>
        </b-col>

        <b-col cols="12" md="6">
          <div class="d-flex align-items-center justify-content-end">
             
            <b-button
              variant="primary"
              @click="addAbilitySidebarAtivo = true"
            >
              <span class="text-nowrap">Adicionar Funcionalidade</span>
            </b-button>
          </div>
        </b-col>
      </b-row>

    </div>

      <b-table ref="refAbilityListTable" class="position-relative" :items="abilities" responsive :fields="tableColumns"
        primary-key="id" :sort-by.sync="sortBy" show-empty empty-text="Sem resultados" :sort-desc.sync="isSortDirDesc">

        <template #cell(id)="data">
          <b-media vertical-align="center">
            <b-link :to="{ name: 'ability-lista', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              #{{ data.item.id }}
            </b-link>
          </b-media>
        </template>

        <template #cell(action)="data">
          {{ data.item.action }}
        </template>
        <template #cell(subject)="data">
          {{ data.item.subject }}
        </template>

        <template #cell(remover)="data">
          <b-button variant="link" size="sm" class="float-right" @click="removerAbility(data.item.id)">
            <feather-icon icon="Trash2Icon" size="18" class="text-danger" />
          </b-button>
        </template>
      </b-table>
      
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
          </b-col>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalLista"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @input="mudarPagina"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>

    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BSpinner 
} from 'bootstrap-vue'
import vSelect from 'vue-select'


// CARREGANDO DE PÁGINA PARA API
import VueElementLoading from 'vue-element-loading'

import useJwt from '@/auth/jwt/useJwt'
import AbilityListaAdd from './AbilityListaAdd.vue'

export default {
  components: {
    AbilityListaAdd,
    
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,

    vSelect,
    VueElementLoading,
  },
  data() {
    return {
      addAbilitySidebarAtivo: false,
      abilities: [],
      tableColumns: [
        { key: 'id', label: 'ID', sortable: true, thStyle: { width: '10%' } },
        { key: 'subject', label: 'Funcionalidade', sortable: true, thStyle: { width: '35%' }},
        { key: 'action', label: 'Ação', sortable: true, thStyle: { width: '35%' } },
        { key: 'remover', label: '', sortable: true, thStyle: { width: '5%' } },
      ],
      perPage: 10,
      currentPage: 1,
      totalLista: 0,
      perPageOptions: [10, 25, 50, 100],
      searchQuery: '',
      sortBy: 'id',
      isSortDirDesc: true,
      refAbilityListTable: null,
      carregamentoApi: false,
      respostaApi: false,
    }
  },
  created() {
    this.carregarLista()
  },
  methods: {
    carregarLista() {
      this.carregamentoApi = true
      const paginaAtual = this.currentPage - 1
      useJwt.abilityListar({
        page: paginaAtual,
        size: this.perPage,
        sort: 'DESC',
      })
        .then(response => {
          this.abilities = response.data.content
          this.totalLista = response.data.totalElements
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.carregamentoApi = false
        })
    },
      mudarPagina() {
        this.carregarLista()
      },

      alterarPorPagina() {
        this.carregarLista()
      },
      removerAbility(abilityId) {
      this.$swal({
        title: 'Tem certeza?',
        text: 'Você está prestes a remover esta funcionalidade. Esta ação não pode ser revertida.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, remover',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-secondary ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        this.respostaApi = true
        if (result.isConfirmed) {
          useJwt.abilityRemover({
            abilityId: abilityId
          })
          .then(response => {
            this.$swal({
              title: 'Funcionalidade Removida!',
              text: 'A funcionalidade foi removida.',
              icon: 'success',
              confirmButtonText: 'OK',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            }).then(() => {
              this.carregarLista();
            });
          })
          .catch(error => {
            this.$swal({
              title: 'Erro!',
              text: error.response.data || 'Não é possível remover a ability porque está associada a um perfil.',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          })
          .finally(() => {
            this.respostaApi = false; 
          });
        }
      })
    },
  },

}

</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>