<template>
    <b-sidebar
      id="add-new-user-sidebar"
      :visible="addAbilitySidebarAtivo"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:add-ability-sidebar-ativo', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            Adicionar Funcionalidade
          </h5>
  
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
  
        </div>
  
        <!-- BODY -->
        <validation-observer
          #default="{invalid}"
          ref="abilityForm"
        >
          <!-- Form -->
          <b-form
            ref="abilityForms"
            class="p-2"
            @submit.prevent="abilityCadastrar"
          >
  
          <b-form-group label="Funcionalidade" label-for="subject">
            <validation-provider #default="{ errors }" name="subject" rules="required">
              <b-form-input id="subject" v-model="subject" :state="errors.length > 0 ? false:null"
                placeholder="Digite o nome da Funcionalidade"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          
            <b-form-group label="Ação" label-for="action">
              <validation-provider #default="{ errors }" name="action" rules="required">
                <b-form-input id="action" v-model="action" :state="errors.length > 0 ? false:null"
                  placeholder="Digite o nome da Action"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            
            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
                :disabled="invalid || respostaApi"
              >
                Adicionar
                <b-spinner
                  v-if="respostaApi"
                  small
                  label="Carregando"
                />
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                Cancelar
              </b-button>
            </div>
  
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </template>
  
  <script>
  import {
    BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BSpinner,
  } from 'bootstrap-vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required } from '@validations'
  import formValidation from '@core/comp-functions/forms/form-validation'
  import Ripple from 'vue-ripple-directive'
  import vSelect from 'vue-select'
  import useJwt from '@/auth/jwt/useJwt'
  import { localize } from "vee-validate";
  
  localize({
    en: {
      messages: {
      },
      fields: {
        action: {
          required: 'O action é obrigatório.',
        },
        subject: {
          required: 'O subject é obrigatório.',
        },
      }
    }
  });

  export default {
    components: {
      BSidebar,
      BForm,
      BFormGroup,
      BFormInput,
      BFormInvalidFeedback,
      BButton,
      BSpinner,
      vSelect,
      ValidationProvider,
      ValidationObserver,
    },
    directives: {
      Ripple,
    },
    model: {
      prop: 'addAbilitySidebarAtivo',
      event: 'update:add-ability-sidebar-ativo',
    },
    props: {
      addAbilitySidebarAtivo: {
        type: Boolean,
        required: true,
      },
    },
    data() {
      return {
        action: '',
        subject: '',
        respostaApi: false,
      }
    },
    methods: {
  
    abilityCadastrar() {
        this.$refs.abilityForm.validate().then(success => {
            if (success) {
            this.respostaApi = true


            useJwt.abilityCadastrar({
                action: this.action,
                subject: this.subject
            })
                .then(response => {
                this.$swal({
                    title: 'Ability cadastrada!',
                    text: 'A Ability foi cadastrada com sucesso.',
                    icon: 'success',
                    customClass: {
                    confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,

                }).then(result => {
                    if (result.isConfirmed) {
                    this.$refs.abilityForms.reset()
                    this.$emit('update:add-ability-sidebar-ativo', false)
                    this.$emit('recarregarTabela')
                    } else {
                    this.$refs.abilityForms.reset()
                    this.$emit('update:add-ability-sidebar-ativo', false)
                    this.$emit('recarregarTabela')
                    }
                })
                })
                .catch(error => {
                this.$swal({
                    title: 'Erro!',
                    text: error.response.data.message,
                    icon: 'error',
                    customClass: {
                    confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                })
                })
                .finally(() => {
                this.respostaApi = false
                })
            }
        })
      }  
    },
  
  }
  </script>
  
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  
  #add-new-user-sidebar {
    .vs__dropdown-menu {
      max-height: 200px !important;
    }
  }
  </style>
  